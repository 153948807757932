import {Route} from '@angular/router';
import {canActivateLoggedInChild} from '@indosuara/auth';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

export const appRoutes: Route[] = [
  {
    path: '', redirectTo: '/shop', pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then(
          (m) => m.LoginComponent,
      ),
  },
  {
    path: 'login/:redirectUrl',
    loadComponent: () =>
      import('./login/login.component').then(
          (m) => m.LoginComponent,
      ),
  },
  {
    path: 'shop',
    loadComponent: () =>
      import('./select-shop/select-shop.component').
          then((m) => m.SelectShopComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode',
    loadComponent: () => import('./select-feature/select-feature.component').
        then((m) => m.SelectFeatureComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/checkout',
    loadComponent: () =>
      import('./checkout/checkout.component').then((m) => m.CheckoutComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/orders',
    loadComponent: () =>
      import('./orders/orders.component').then((m) => m.OrdersComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/check-item',
    loadComponent: () =>
      import('./check-item/check-item.component').
          then((m) => m.CheckItemComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/check-item/:itemId',
    loadComponent: () =>
      import('./check-item-details/check-item-details.component').
          then((m) => m.CheckItemDetailsComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/remit',
    loadComponent: () =>
      import('./shop-remit/shop-remit.component').
          then((m) => m.ShopRemitComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/remit-transactions',
    loadComponent: () =>
      import('./remit-transactions/remit-transactions.component').
          then((m) => m.RemitTransactionsComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/stock-transfers',
    loadComponent: () =>
      import('./stock-transfer/stock-transfer.component').
          then((m) => m.StockTransferComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/stock-transfers/create',
    loadComponent: () =>
      import('./stock-transfer/stock-transfer-form/stock-transfer-form.component').
          then((m) => m.StockTransferFormComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/stock-transfers/:stockTransferId',
    loadComponent: () =>
      import('./stock-transfer/stock-transfer-detail/stock-transfer-detail.component').
          then((m) => m.StockTransferDetailComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shop/:shopCode/remit-approve',
    loadComponent: () =>
      import('@indosuara/remit').
          then((m) => m.RemitApproveCashTrxComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('@indosuara/auth-ui').then(
          (m) => m.LogoutComponent,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: '**', component: PageNotFoundComponent,
    canActivate: [canActivateLoggedInChild],
  },
];
